@import "variables";

%button-base {
    display: block;
    outline: none;
    border: $border-width $border-style $border-color-6;
    border-radius: $border-radius;
    height: 2.5em;
    background: $gradient-3;
    font-weight: 500;
    font-size: $font-size-3;
    color: $font-color-2; 
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    box-shadow: none;
    cursor: pointer;

    &:hover {
        background: $gradient-3-inverse;
        text-decoration: none;
        color: $font-color-2; 
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-5;
    }

    &.disabled,
    &:disabled {
        opacity: $button-disabled-opacity;
        cursor: default;
    }

    svg {
        margin: -.25em .3em 0 0;
        width: 1.4em;
        height: 1em;
    }

    &.icon-right {
        position: relative;

        svg {
            position: absolute;
            top: calc(50% - .5em);
            right: .3125em;
            margin: 0;
        }
    } 

    &.icon-left {
        position: relative;

        svg {
            position: absolute;
            top: calc(50% - .5em);
            left: .3125em;
            margin: 0;
        }
    } 
}

a%button-base {
    line-height: 2.5em;
    text-align: center;
}

.button {
    @extend %button-base;
}

.button-2 {
    @extend %button-base;

    border-color: $border-color-3;
    background: $gradient-1;

    &:hover {
        background: $gradient-1-inverse;
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-6;
    }
}

.button-3 {
    @extend %button-base;

    border-color: $border-color-3;
    background: $gradient-4;
    color: $font-color-2;

    &:hover {
        background: $gradient-4-inverse;
        color: $font-color-6;
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-10;
    }
}

.button-4 {
    @extend %button-base;

    border-color: $border-color-3;
    background: $gradient-7;
    color: $font-color-6;

    &:hover {
        background: $gradient-7-inverse;
        color: $font-color-6;
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-6;
        color: $font-color-6;
    }
}

.button-5 {
    @extend %button-base;

    border-color: $border-color-3;
    background: $gradient-3;
    margin-bottom: 1em;
    &:hover {
        background: $gradient-3-inverse;
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-5;
    }
}

.button-6 {
    @extend %button-base;

    border-color: $color-science-blue;
    background: $color-science-blue;
    color: white;
    &:hover {
        background: lighten($color-science-blue, 20%);
    }

    &:active {
        box-shadow: 0 0 0 $box-shadow-spread $box-shadow-color-4;
    }
}