@import "../../../assets/stylesheets/colors";
@import "../../../assets/stylesheets/variables";

.TkWhatsAppBalloon {

  &__container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 10px;
    bottom: 20px;
    z-index: 19;
    transition: all .3s ease;
    transform: scale(0) translate(-50%, 100%);
    opacity: 0;

    &.active {
      transform: scale(1) translate(0, 0);
      opacity: 1;
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.2em;
    height: 3.2em;
    color: $font-color-6;
    font-size: $font-size;
    border-radius: 50%;
    box-shadow: 0 .1875em .625em $box-shadow-color-2;
    background: rgb(37, 211, 102);
    cursor: pointer;
    position: relative;
    transition: all .3s ease;
    transition-delay: .1s;
    transform-origin: bottom left;

    &:hover {
      color: $font-color-6;
    }

    svg {
      width: 1.8em;
      height: 1.8em;
    }

    &::after {
      display: inline-flex;
      content: '2';
      align-items: center;
      justify-content: center;
      width: 1.2em;
      height: 1.2em;
      border-radius: .7em;
      background: #f00;
      color: #fff;
      font-size: .8em;
      padding: .7em;
      font-weight: bold;
      position: absolute;
      right: -5px;
      top: -5px;
    }

    &--disable {
      transform: scale(0);
      opacity: 0;
    }

    &--hide::after {
      opacity: 0;
      transform: scale(0);
    }
  }

  &__hello {
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333;
    padding: 0.5rem;
    margin-left: 15px;
    max-width: 300px;
    position: relative;
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
    user-select: none;
    transition: all .3s ease;

    &--hide {
      opacity: 0;
      transform: scale(0);
    }

    &::before {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      left: 2px;
      bottom: 15px;
      background-color: #fff;
      transform: translateX(-6px) rotate(45deg);
      pointer-events: none;
      box-shadow: -2px 2px 2px 0 rgb(0 0 0 / 10%);
    }
  }

  &__chat {
    position: fixed;
    left: 10px;
    bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 20;
    width: 350px;
    background-size: cover;
    transition: all .3s ease;
    transform: scale(0);
    transform-origin: bottom left;
    opacity: 0;

    &--active {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__header {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #075e54;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 4em auto 4em;

    > .logo {
      color: #fff;
      width: 2.5em;
      height: 2.5em;
    }

    > .title {
      justify-self: start;
      color: #fff;
      font-size: 1em;
      line-height: 1em;
      font-weight: bold;
      user-select: none;

      > small {
        font-weight: normal;
        font-size: .7em;
      }
    }

    > .close {
      color: #fff;
      cursor: pointer;
      > svg {
        width: 1.5em;
        height: 1.5em;
      }
    }
  }

  &__shell-container {
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 10px;
    align-items: center;
    justify-items: center;
    width: 100%;

    > button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background-color: #075e54;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      color: #fff;

      > svg {
        width: 1.2em;
        height: 1.2em;
      }
    }
  }

  &__shell {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 2.5em auto;
    background-color: #fff;
    height: 50px;
    width: 100%;
    border-radius: 25px;

    > .smile {
      width: 1.5em;
      height: 1.5em;
      color: rgba(0, 0, 0, .2);
    }

    > input {
      border: none;
      outline: none;
      background-color: transparent;
      padding-right: 10px;
    }
  }

  &__body {
    padding: 10px;
  }

  &__message {
    display: grid;
    grid-template-columns: 8px minmax(200px, 280px);
    color: #fff;

    > p {
      padding: 10px;
      color: #000;
      font-size: .9em;
      background-color: #fff;
      border-radius: 0 5px 5px 5px;


      > span > a {
        border-radius: 5px;
        margin-top: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        border: 1px solid rgba(96,165,250);
        color: rgba(96,165,250);
        transition: all .3s ease;

        &:hover, &:visited, &:active {
          text-decoration: none;
        }

        &:hover {
          color: #fff;
          background-color: rgba(96,165,250);
        }
      }
    }
  }
}

@media (max-width: 369px) {
  .TkWhatsAppBalloon {
    &__chat {
      width: calc(100vw - 20px);
    }
  }
}
