@import "colors";

$background-color: $color-white;
$background-color-2: $color-athens-gray;
$background-color-3: $color-cerise;
$background-color-4: $color-chambray;
$background-color-5: $color-persian-red;
$background-color-6: $color-black-haze;
$background-color-7: $color-science-blue;
$background-logo-teky: url('../../assets/images/logo-teky-03.svg') no-repeat;

$border-radius: .25em;
$border-radius-2: .3em;
$border-radius-3: .75em;
$border-width: .03125em;
$border-width-2: .0625em;
$border-width-3: .015625em;
$border-style: solid;
$border-color: $color-ghost;
$border-color-2: $color-science-blue;
$border-color-3: $color-black;
$border-color-4: $color-lochmara;
$border-color-5: $color-gray-chateau;
$border-color-6: $color-abbey;
$border-color-7: rgba($color-gray-chateau, .5);
$border-color-8: rgba($color-gray-chateau, .7);

$box-shadow-opacity: .25;
$box-shadow-opacity-2: .5;
$box-shadow-opacity-3: .2;
$box-shadow-opacity-4: .14;
$box-shadow-opacity-5: .12;
$box-shadow-color: rgba($color-science-blue, $box-shadow-opacity);
$box-shadow-color-2: rgba($color-black, $box-shadow-opacity);
$box-shadow-color-3: rgba($color-azure-radiance, $box-shadow-opacity);
$box-shadow-color-4: rgba($color-dodger-blue, $box-shadow-opacity-2);
$box-shadow-color-5: rgba($color-gray-chateau, $box-shadow-opacity);
$box-shadow-color-6: rgba($color-golden-tainoi, $box-shadow-opacity-2);
$box-shadow-color-7: rgba($color-black, $box-shadow-opacity-3);
$box-shadow-color-8: rgba($color-black, $box-shadow-opacity-4);
$box-shadow-color-9: rgba($color-black, $box-shadow-opacity-5);
$box-shadow-color-10: rgba($color-jacksons-purple, $box-shadow-opacity-5);
$box-shadow-spread: .2em;
$box-shadow-spread-2: -.0625em;
$box-shadow-spread-3: 0;

$breadcrumb-separator: ">";

$button-disabled-opacity: .65;

$caption-color: $color-pale-sky;

$fill-color: $color-blue-bayoux;
$fill-color-2: $color-black;

$font-family: "Roboto", sans-serif;
$font-size: 1em; // Assumes the browser default, typically `16px`
$font-size-2: .75em;
$font-size-3: .875em;
$font-size-4: 1.125em;
$font-size-5: 1.375em;
$font-size-6: .65em;
$font-color: $color-shark;
$font-color-2: $color-black;
$font-color-3: $color-tuna;
$font-color-4: $color-gray-chateau;
$font-color-5: $color-abbey;
$font-color-6: $color-white;
$font-color-7: $color-havelock-blue;
$font-color-8: $color-denim;
$font-color-9: $color-tall-poppy;
$font-color-10: #bababa;

$whatsapp-color-green: #25D366;
$whatsapp-color-red: red;

$placeholder-color: $color-gray-chateau;
$placeholder-opacity: 1;

$text-shadow-color: $color-trout;
$text-disabled-color: $color-pale-sky;

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}