// Color names: http://chir.ag/projects/name-that-color
$color-white: #FFFFFF;
$color-black-haze: #F4F5F5;
$color-ghost: #CED4DA;
$color-iron: #C8CDCF;
$color-gray-chateau: #ADB2B4;
$color-athens-gray: #E9ECEF;
$color-mercury: #E8E8E8;
$color-dust-gray: #999999;
$color-dove-gray: #707070;
$color-trout: #495057;
$color-pale-sky: #6C757D;
$color-abbey: #49494C;
$color-tuna: #333337;
$color-shark: #212529;
$color-black: #000000;
$color-blue-bayoux: #4A6572;
$color-havelock-blue: #5283D2;
$color-lochmara: #007DC4;
$color-science-blue: #006CB9;
$color-dodger-blue: #268FFF;
$color-denim: #0E6CB9;
$color-royal-blue: #285AEB;
$color-azure-radiance: #007BFF;
$color-chambray: #3B5998;
$color-christalle: #310077;
$color-jacksons-purple: #2B269A;
$color-atlantis: #69CA3B;
$color-vida-loca: #56A61E;
$color-japanese-laurel: #00A900;
$color-picasso: #FDF497;
$color-golden-tainoi: #FFD060;
$color-ronchi: #F0C14B;
$color-selective-yellow: #FFB400;
$color-carrot-orange: #ED9220;
$color-sunset-orange: #FD5949;
$color-persian-red: #c4302b;
$color-tall-poppy: #B02D28;
$color-cerise: #D6249F;

$color-excel: #007600;
$color-pdf: #E1001A;

$accent-color: $color-lochmara;
$accent-gray: $color-gray-chateau;
$accent-light-gray: $color-iron;
$accent-yellow: $color-ronchi;
$dark-gray: $color-abbey;
$fourth-gray: $color-black-haze;
$primary-accent-green: lighten($color-japanese-laurel, 10%);
$primary-color: $color-science-blue;
$primary-dark-color: $color-blue-bayoux;
$primary-gray: $color-dust-gray;
$primary-green: $color-japanese-laurel;
$primary-orange: $color-carrot-orange;
$primary-red: $color-tall-poppy;
$primary-text-color: $color-shark;
$primary-yellow: $color-selective-yellow;
$primary-purple: #9B2CE5;
$primary-purple-two: #DBA4FF;
$secondary-color: $color-blue-bayoux;
$secondary-gray: rgba($color-gray-chateau, .5);
$third-gray: $color-dove-gray;

$gradient-1: linear-gradient(to bottom, lighten($color-selective-yellow, 20%), $color-selective-yellow);
$gradient-1-inverse: linear-gradient(to bottom, $color-selective-yellow, lighten($color-selective-yellow, 20%));
$gradient-2: linear-gradient(to bottom, lighten($color-carrot-orange, 20%), $color-carrot-orange);
$gradient-2-inverse: linear-gradient(to bottom, $color-carrot-orange, lighten($color-carrot-orange, 20%));
$gradient-3: linear-gradient(to bottom, lighten($color-iron, 20%), $color-iron);
$gradient-3-inverse: linear-gradient(to bottom, $color-iron, lighten($color-iron, 20%));
$gradient-4-inverse: linear-gradient(to bottom, $primary-purple, $primary-purple-two);
$gradient-4: linear-gradient(to bottom, $primary-purple-two, $primary-purple);
$gradient-5: linear-gradient($color-atlantis, $color-vida-loca);
$gradient-6: radial-gradient(circle at 30% 107%, $color-picasso 0%, $color-picasso 5%, $color-sunset-orange 45%, $color-cerise 60%, $color-royal-blue 90%);
$gradient-7: linear-gradient(to bottom, lighten($color-shark, 20%), $color-shark);
$gradient-7-inverse: linear-gradient(to bottom, $color-shark, lighten($color-shark, 20%));
$gradient-8-inverse: linear-gradient(to bottom, $color-science-blue, lighten($color-science-blue, 20%));
$gradient-8: linear-gradient(to bottom, lighten($color-science-blue, 20%), $color-science-blue);

$blue: $color-science-blue;
$red: $color-tall-poppy;
$orange: $color-carrot-orange;
$yellow: $color-selective-yellow;
$green: $color-japanese-laurel;

$colors: ();
$colors: map-merge((
    "blue": $color-science-blue,
    "red": $color-tall-poppy,
    "orange": $color-carrot-orange,
    "yellow": $color-selective-yellow,
    "green": $color-japanese-laurel
), $colors);

$theme-colors: ();
$theme-colors: map-merge((
    "primary": $color-science-blue,
    "secondary": $color-abbey,
    "success": $color-japanese-laurel,
    "orange": $color-carrot-orange,
    "warning": $color-selective-yellow,
    "danger": $color-tall-poppy,
    "dark": $color-black,
), $theme-colors);